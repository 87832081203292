// Fonts
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

$fontTitle: 'Raleway', sans-serif;
$fontText: 'Montserrat', sans-serif;

$mainColor: #fbf9f6;
$secondaryColor: #f4e2e0;
$accentColor: #d2d4ce;
$brownColor: #fbf9f6;
$blackColor: #272727;
$whiteColor: #fbf9f6;

// Sizing
$maxWidth: 1200px;

$heroImg: '/images/website-images/hero.png';
