.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;

  .pageHeader {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;

    input {
      padding: 0.5rem;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;

      &::placeholder {
        color: #aaa;
      }
    }

    .passwordInputDiv {
      position: relative;

      img {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        right: 1rem;
        cursor: pointer;
      }

      .passwordInput {
        width: 100%;
      }
    }

    .forgotPasswordLink {
      text-align: right;
      margin-bottom: 1rem;
      color: #0077cc;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .signUpBar,
    .signInBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #333;
      padding: 1rem;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 1rem;
      height: 3rem;

      .signUpText,
      .signInText {
        color: #fff;
        font-size: 1.25rem;
      }

      .signUpButton,
      .signInButton {
        background: none;
        border: none;
        cursor: pointer;

        svg {
          fill: #fff; // The SVG icon color
        }
      }
    }
  }

  .OAuth {
    margin-bottom: 1rem;
  }

  .socialIconDiv {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    margin: 1.5rem;
    width: 3rem;
    height: 3rem;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border: none;

    .socialIconImg {
      width: 100%;
      img {
        overflow: clip;
        overflow-clip-margin: content-box;
      }
    }
  }

  .registerLink {
    color: #0077cc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
